import React, { useState } from 'react';
import axiosInstance from '../api/axiosInstance'

const HireFromUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            await axiosInstance.post('hire', {
                name,
                email,
                message,
            });
            setSuccess(true);
            setError('');
            setName('');
            setEmail('');
            setMessage('');
        } catch (err) {
            setError('Failed to send your message. Please try again later.');
            setSuccess(false);
        }
    };

    return (
        <div className="container mx-auto px-4 py-16">
            <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">Hire From Us</h1>
            <form onSubmit={handleSubmit} className="max-w-xl mx-auto bg-white p-8 rounded-lg shadow-lg">

                {/* Success or Error Message */}
                {success && (
                    <p className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-6">
                        Your message has been sent successfully!
                    </p>
                )}
                {error && (
                    <p className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
                        {error}
                    </p>
                )}

                {/* Name Input */}
                <div className="mb-6">
                    <label className="block text-gray-700 font-semibold mb-2">Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 transition duration-200"
                        placeholder="Your full name"
                        required
                    />
                </div>

                {/* Email Input */}
                <div className="mb-6">
                    <label className="block text-gray-700 font-semibold mb-2">Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 transition duration-200"
                        placeholder="Your email address"
                        required
                    />
                </div>

                {/* Message Input */}
                <div className="mb-6">
                    <label className="block text-gray-700 font-semibold mb-2">Message</label>
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 transition duration-200"
                        placeholder="Briefly explain your request"
                        rows="5"
                        required
                    />
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300 ease-in-out"
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

export default HireFromUs;

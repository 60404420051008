// CardSection.js
import React from 'react';
import Card from './Card';
import './style/masterApproach.css';

// Sample icons (replace these paths with your actual icon paths)
import icon1 from './asset/cardIcons/icon1.jpg';
import icon2 from './asset/cardIcons/icon2.jpg';
import icon3 from './asset/cardIcons/icon3.jpg';
import icon4 from './asset/cardIcons/icon4.jpg';
import icon5 from './asset/cardIcons/icon5.jpg';
import icon6 from './asset/cardIcons/icon6.jpg';


const MasterApproach = () => {
    const cardData = [
        {
            icon: icon1,
            title: "6+ work-like projects & 20+ micro skilling exercises",
            description: "Master fullstack & backend development in a",
            highlight: "real work-like environment",
        },
        {
            icon: icon2,
            title: "Live chat support & dedicated success managers",
            description: "To ensure a smooth and effortless learning experience, we provide ",
            highlight: "daily live technical support for 12+ hours",
        },
        {
            icon: icon3,
            title: "80+ live guided sessions",
            description: "Mentorship by",
            highlight: "industry experts from top tech companies",
        },
        {
            icon: icon4,
            title: "Exclusive Career Services",
            description: "Extensive mock interviews, resume building, and mock assessments with",
            highlight: "detailed interview prep sprints",
        },
        {
            icon: icon5,
            title: "300+ DSA problems & 20+ System Design challenges",
            description: "Build problem-solving skills to",
            highlight: "ace technical interviews",
        },
        {
            icon: icon6,
            title: "one-to-one online doubt solving",
            description: "Get instant clarity with one-to-one online doubt-solving sessions",
            highlight: "personalized just for you!",
        },
    ];

    return (
        <div className='card-section-container-master-approach rubik-hero-section bg-white'>
            <div>
                <h2 className='text-4xl  master-approach-header manrope-hero-section'>Work Experience-Based Learning Approach
                to Master In-Demand Developer Skills</h2>
            </div>
            <div className="card-section-master-approach">
                {cardData.map((card, index) => (
                    <Card
                        key={index}
                        icon={card.icon}
                        title={card.title}
                        description={card.description}
                        highlight={card.highlight}
                    />
                ))}
            </div>
        </div>
    );
};


export default MasterApproach

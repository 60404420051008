import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../api/axiosInstance';

const ProtectedRoute = ({ element: Element, allowedRoles }) => {
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const { setIsAuthenticated, setUserRole, isAuthenticated, userRole } = useAuth();

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const response = await axiosInstance.get('/auth/checkAuth');
                if (response.data.success) {
                    setUserRole(response.data.user.role);
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
                console.log('protected response: ', response.data);
            } catch (error) {
                console.error('Error fetching auth status:', error);
            } finally {
                setIsLoading(false);
            }
        };
        checkAuthStatus();
    }, [setIsAuthenticated, setUserRole]); // Include dependencies here

    console.log(`protected page : ${isAuthenticated} , ${userRole}`)

    if (isLoading) {
        return <div class="flex items-center justify-center h-screen w-screen bg-gray-100">
            <div class="w-16 h-16 sm:w-12 sm:h-12 border-4 border-t-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
            <span class="ml-4 text-xl sm:text-lg md:text-xl lg:text-2xl font-semibold text-gray-700">Loading...</span>
        </div>

            ; // Show a loading message while fetching status
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />; // Redirect to login if not authenticated
    }

    if (!allowedRoles.includes(userRole)) {
        return <Navigate to="/" />; // Redirect to home if role is not allowed
    }

    return <Element />; // Render the protected component
};

export default ProtectedRoute;

import React, { useEffect, useRef, useState } from "react";
import "../styles/chatAssistant.css";
import chatIcon from "./asset/chatIcon.jpg"; // replace with the actual path of the image
import { useAuth } from "../context/AuthContext";
import axiosInstance from "../api/axiosInstance";

const tapSound = process.env.PUBLIC_URL + "/notification-sound.mp3";

const ChatAssistant = () => {
    const { showModal, setShowModal } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    // const [showModal, setShowModal] = useState(false); // State for modal

    const [phone, setPhone] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [buttonText, setButtonText] = useState('Request Callback');

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission

        try {
            // Send the data to the backend
            await axiosInstance.post('callback', {
                firstName,
                lastName,
                email,
                phone,
            });
            setButtonText('Sent!'); // Update button text to indicate success

            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('+91');

            // Reset button text after 3 seconds
            setTimeout(() => {
                setShowModal(false);
                setButtonText('Request Callback');
            }, 1000);

        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Failed to send request. Please try again.');
        }
    };

    // Play sound function
    const playSound = () => {
        const audio = new Audio(tapSound);
        audio.play().catch(error => console.log("Audio play error:", error));
    };

    // Toggle chat popup and play sound on click
    const handleClick = () => {
        setIsOpen(!isOpen);
        setShowMessage(false);
        if (!isOpen) {
            playSound();
        }
    };

    // Open/close the modal
    const handleModalToggle = () => {
        setShowModal(!showModal);
        setIsOpen(false); // Close chat popup when modal opens
    };

    // Show message on hover
    const handleHover = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 5000);
    };

    const chatButtonRef = useRef(null);  // Create a reference for the chat button

    // Function to start shake and wave animation

    // Set the interval to trigger the shake and wave every 2 minutes
    useEffect(() => {
        const startShakeAndWave = () => {
            if (chatButtonRef.current) {
                chatButtonRef.current.classList.add('wave');  // Add wave class

                // Remove wave animation after it completes (2 seconds)
                setTimeout(() => {
                    chatButtonRef.current.classList.remove('wave');
                }, 3000);
            }
        };
        const intervalId = setInterval(() => {
            startShakeAndWave();
        }, 2 * 10 * 1000);  // 2 minutes interval

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="chat-bot-container rubik-hero-section ">
            {/* Floating Button */}
            <div
                ref={chatButtonRef} // Apply ref to the chat button
                className="chat-button"
                onMouseEnter={handleHover}
                onClick={handleClick}
            >
                <img src={chatIcon} alt="Chat Assistant" />
                {showMessage && !isOpen && (
                    <div className="chat-message">
                        <p>Hey there! My name is Sam. How may I help you?</p>
                    </div>
                )}
            </div>

            {/* Chat Popup */}
            {isOpen && (
                <div className="chat-popup">
                    <div>
                        <span className="bg-black text-white px-3 py-1 rounded-lg">Request Callback</span>
                        <i className="fa-solid fa-phone-flip p-4 bg-green-500 rounded-full ml-3" onClick={handleModalToggle}></i>
                    </div>
                </div>
            )}

            {/* Modal for Request Callback */}
            {showModal && (
                <>
                    <div className="modal-backdrop" onClick={handleModalToggle}></div>
                    <div className="callback-modal">
                        <span className="modal-close" onClick={handleModalToggle}>✕</span>
                        <h2 className="manrope-hero-section header-chat-bot" >Request Callback</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-input">
                                <label htmlFor="firstName" className="block text-sm font-medium text-lightGray">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="firstName"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    className="w-full border-gray-300 border-2 p-3 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    required
                                />
                            </div>
                            <div className="modal-input">
                                <label htmlFor="lastName" className="block text-sm font-medium text-lightGray">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="lastName"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    className="w-full border-gray-300 border-2 p-3 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    required
                                />
                            </div>

                            <div className="modal-input">
                                <label htmlFor="email" className="block text-sm font-medium text-lightGray">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full border-gray-300 border-2 p-3 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    required
                                />
                            </div>

                            <div className="modal-input">
                                <label htmlFor="phone" className="block text-sm font-medium text-lightGray ">
                                    Phone
                                </label>
                                <input
                                    type="number"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    className="p-10 w-full border-gray-300 border-2 rounded-lg shadow-sm"
                                    required
                                />
                            </div>
                            <button className="modal-submit manrope-hero-section" type="submit">
                                {buttonText}
                            </button>
                        </form>
                    </div>
                </>
            )}
        </div>
    );
};

export default ChatAssistant;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles/login.css';
import axiosInstance from '../api/axiosInstance';

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');
    try {
      let response = await axiosInstance.post('auth/login', 
        { userEmail, password }
      );

      localStorage.setItem('token', response.data.token);

      if (response.status === 200) {
        setSuccessMessage(response.data.message);
        login(); 
        setTimeout(() => navigate(response.data.redirectUrl), 1000);
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Login failed');
    }
  };

  return (
    <div className="login-page-container">
      <form 
        onSubmit={handleLogin} 
        className="bg-white p-10 rounded-2xl shadow-lg max-w-md w-full space-y-6 transform transition duration-500 hover:shadow-xl"
      >
        <h2 className="text-3xl font-extrabold text-center text-blue-700 mb-6">Welcome Back to Learnify</h2>

        {successMessage && (
          <div className="bg-green-100 text-green-700 p-3 rounded-lg text-center mb-4">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="bg-red-100 text-red-700 p-3 rounded-lg text-center mb-4">
            {errorMessage}
          </div>
        )}

        <div className="space-y-4">
          <input
            type="email"
            placeholder="Email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 placeholder-gray-500"
          />

          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 placeholder-gray-500"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 text-white font-semibold py-3 rounded-lg hover:bg-blue-700 transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105"
        >
          Login
        </button>

        <div className="text-center text-gray-600 mt-4">
          Don’t have an account?{" "}
          <Link to='/signup' className="text-blue-600 hover:underline font-semibold">
            Sign Up
          </Link>
        </div>

        <div className="text-center text-gray-600 mt-2">
          <Link to='/forgot-password' className="text-blue-600 hover:underline font-semibold">
            Forgot Password?
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;

import React, { createContext, useState, useContext, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [currentUser, setCurrentUser] = useState({});
  const [showModal, setShowModal] = useState(false); // State for modal

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await axiosInstance.get('/auth/checkAuth');

        if (response.data.success) {
          setUserRole(response.data.user.role);
          setIsAuthenticated(true);
          setCurrentUser(response.data.user)
        } else {
          setIsAuthenticated(false);
          localStorage.removeItem('isAuthenticated');
          localStorage.removeItem('token');
        }
      } catch (error) {
        console.error('Error checking authentication status:', error);
      }
    };


    checkStatus();
  }, []);

  const login = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token'); // Clear token on logout
    setCurrentUser({});
    setUserRole(null);
  };

  return (
    <AuthContext.Provider value={{ showModal, setShowModal, setIsAuthenticated, setUserRole, isAuthenticated, userRole, login, logout, currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

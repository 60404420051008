// src/api/axiosInstance.js
import axios from 'axios';

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: 'https://learnify-eqfi.onrender.com', // Update with your API's base URL
  withCredentials: true,            // Allow cookies to be sent with requests
});

// Interceptor to add Authorization header to every request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Adjust according to how you store the token
    console.log("Token in interceptor:", token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;

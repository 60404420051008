// FAQItem.js
import React, { useState } from 'react';
import './style/FAQitem.css'

function FAQItem({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='faq-item-mobile'>
            <div style={styles.question} onClick={toggleOpen}>
                <span>{question}</span>
                <span>{isOpen ? <svg style={styles.openCloseIcon} aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" class="svg-inline--fa fa-plus undefined w-[32px] transition-all rotate-45 h-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432 256C432 269.3 421.3 280 408 280h-160v160c0 13.25-10.75 24.01-24 24.01S200 453.3 200 440v-160h-160c-13.25 0-24-10.74-24-23.99C16 242.8 26.75 232 40 232h160v-160c0-13.25 10.75-23.99 24-23.99S248 58.75 248 72v160h160C421.3 232 432 242.8 432 256z"></path></svg> : <svg style={styles.openCloseIcon} aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" class="svg-inline--fa fa-plus undefined w-[32px] transition-all rotate-180 h-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432 256C432 269.3 421.3 280 408 280h-160v160c0 13.25-10.75 24.01-24 24.01S200 453.3 200 440v-160h-160c-13.25 0-24-10.74-24-23.99C16 242.8 26.75 232 40 232h160v-160c0-13.25 10.75-23.99 24-23.99S248 58.75 248 72v160h160C421.3 232 432 242.8 432 256z"></path></svg>}</span>
            </div>
            {isOpen && <div style={styles.answer}>{answer}</div>}
        </div>
    );
}

const styles = {
    question: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: '700',
        fontSize: '16px'
    },
    answer: {
        paddingTop: '10px',
        fontSize: '15px',
        color: '#00000',
        fontWeight: '450'
    },
    openCloseIcon: {
        color:'#6E7271'
    }
};

export default FAQItem;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../context/AuthContext';

const Signup = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!userName || !userEmail || !phoneNo || !password) {
      setErrorMessage('All fields are required');
      return;
    }

    try {
      const response = await axiosInstance.post(
        'auth/signup',
        {
          username: userName,
          email: userEmail,
          mobileNo: phoneNo,
          password: password,
        }
      );

      if (response.status === 201) {
        // Successful signup and auto-login
        setSuccessMessage(`Welcome, ${response.data.user.username}! Redirecting...`);
        setErrorMessage('');
        localStorage.setItem('token', response.data.token); 
        setTimeout(() => {
          login();
          navigate(response.data.redirectUrl)
        }, 1000);
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        setErrorMessage(data?.message || 'Error registering user');
        setSuccessMessage('');
        if (data.redirectUrl) {
          setTimeout(() => navigate(data.redirectUrl), 1000);
        }
      } else {
        setErrorMessage('Error registering user');
        setSuccessMessage('');
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-100 via-blue-300 to-blue-500 p-6">
      <form
        onSubmit={handleSignup}
        className="bg-white p-10 rounded-2xl shadow-2xl max-w-md w-full space-y-6 transform transition-all duration-500 hover:shadow-xl"
      >
        <h2 className="text-3xl font-extrabold text-center text-gray-900 mb-4">Create an Account</h2>

        {successMessage && <p className="text-green-500 text-center">{successMessage}</p>}
        {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}

        <div>
          <input
            type="text"
            placeholder="Full Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
          />
        </div>

        <div>
          <input
            type="email"
            placeholder="Email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
          />
        </div>

        <div>
          <input
            type="number"
            placeholder="Phone Number"
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
          />
        </div>

        <div>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 text-white font-semibold py-3 rounded-xl hover:bg-blue-700 transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105"
        >
          Sign Up
        </button>

        <div className="text-center text-gray-700 mt-4">
          Already have an account?{' '}
          <Link to="/login" className="text-blue-600 hover:underline font-semibold">
            Log In
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Signup;

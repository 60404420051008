import React, { useState } from 'react';
import '../styles/login.css';
import axiosInstance from '../api/axiosInstance';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      const response = await axiosInstance.post('/auth/forgot-password', { email });
      setMessage(response.data.message);
    } catch (error) {
      setError(error.response?.data?.message || 'Error resetting password');
    }
  };

  return (
    <div className="login-page-container">
      <form onSubmit={handleForgotPassword} className="bg-white p-10 rounded-2xl shadow-lg max-w-md w-full space-y-6">
        <h2 className="text-3xl font-extrabold text-center text-blue-700 mb-6">Forgot Password</h2>

        {message && (
          <div className="bg-green-100 text-green-700 p-3 rounded-lg text-center mb-4">
            {message}
          </div>
        )}
        {error && (
          <div className="bg-red-100 text-red-700 p-3 rounded-lg text-center mb-4">
            {error}
          </div>
        )}

        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        />

        <button
          type="submit"
          className="w-full bg-blue-600 text-white font-semibold py-3 rounded-lg hover:bg-blue-700 transition duration-300"
        >
          Reset Password
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;

import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './style/custom-phone-input.css';
import './style/heroSection.css'
import axios from 'axios';
import techGuy1 from '../asset/techGuy1_2.jpg'
import htmlTagIcon from '../asset/htmlTagIcon.png'
import curlyIcon from '../asset/curlyIcon.png'
import hamBurgerIcon from '../asset/hamBurgerIcon.png'
import { Link } from 'react-router-dom';

const HeroSection = () => {
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [buttonText, setButtonText] = useState('Request Callback');





  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    try {
      // Send the data to the backend
      await axios.post('https://learnify-eqfi.onrender.com/callback', {
        firstName,
        lastName,
        email,
        phone,
      });
      setButtonText('Sent!'); // Update button text to indicate success

      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('+91');

      // Reset button text after 3 seconds
      setTimeout(() => {
        setButtonText('Request Callback');
      }, 2000);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to send request. Please try again.');
    }
  };
  return (
    <div className="text-black py-12 w-full relative" >
      <div className=" z-20 container mx-auto flex flex-col lg:flex-row items-center justify-evenly px-4 sm:px-8 lg:px-16 space-y-8 lg:space-y-0 lg:space-x-20">
        <div className="  lg:w-1/2 space-y-6 text-center lg:text-left lg:pr-8 ">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold leading-tight manrope-hero-section">
            From Code to Career: Master Full Stack Web Development with Real Projects & Live Classes!
          </h1>
          <ul className="lg:list-disc ml-4 sm:ml-6 space-y-2 text-base sm:text-lg sm:list-none rubik-hero-section">
            <li>Build real-world projects to master in-demand skills.</li>
            <li>Job-ready training with career support.</li>
            <li>Get personalized mentorship from industry experts.</li>
            <li>Access dedicated one-on-one sessions for in-depth doubt clearance and conceptual clarity.</li>
          </ul>
          <div className="mt-6 flex flex-col sm:flex-row sm:justify-center lg:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
            <Link className="enroll-button-hero-section" to={'/checkout'}>
              Enroll Now
            </Link>
            <div className="bg-gradient-to-r from-blue-100 to-cyan-100 text-black px-6 py-4 rounded-lg font-semibold flex justify-around w-full sm:w-auto">
              <div className="text-center mr-3">
                <h4 className="text-sm  text-green-900 font-bold ">FULLY ONLINE</h4>
                <h3 className="text-2xl manrope-hero-section">6 months</h3>
              </div>
              <div className="border-l-2 pl-4 border-gray-500">
                <h4 className="text-sm text-green-900 font-bold">TRIAL SESSION</h4>
                <h3 className="text-2xl manrope-hero-section">FREE</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:w-1/3 bg-gradient-to-r from-blue-100 to-cyan-100 text-black rounded-3xl shadow-lg p-6 sm:px-8 sm:py-10 sm:w-full">
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-lightGray">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="w-full border-gray-300 border-2 p-3 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-lightGray">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="w-full border-gray-300 border-2 p-3 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-lightGray">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full  border-gray-300 border-2 p-3 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-lightGray">
                Phone
              </label>
              <PhoneInput
                country={'in'}
                value={phone}
                onChange={setPhone}
                inputProps={{
                  name: 'phone',
                  required: true,
                  className: 'p-3 w-full border-gray-300 border-2 pl-16 rounded-lg shadow-sm ',
                }}
                containerClass="phone-input-container"
                inputClass="phone-input"
                buttonClass="flag-dropdown"
              />
            </div>

            <div className="mt-6">
              <button
                type="submit"
                className=" manrope-hero-section request-callback-button bg-lightYellowMe zIndexHigher-hero-section"
              >
                {buttonText}
              </button>
            </div>
          </form>
        </div>
      </div>
      <img src={techGuy1} alt="" className='tech-guy-image-1' />
      <img src={htmlTagIcon} alt="" className='html-tag-icon' />
      <img src={curlyIcon} alt="" className='curly-icon' />
      <img src={hamBurgerIcon} alt="" className='ham-burger-icon' />
    </div>
  );
};


export default HeroSection;

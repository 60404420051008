import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './style/workexperienceApproach.css'
import amazonImage from './asset/projectIcons/CardOne.jpg';
import airbnbImage from './asset/projectIcons/laptopImage.jpg'
import netflixImage from './asset/projectIcons/popcornImage.jpg'
import zomatoImage from './asset/projectIcons/zomatoImage.jpg'
import zerodhaImage from './asset/projectIcons/zerodhaImage.jpg'
import newsImage from './asset/projectIcons/newspaperImage.jpg'
import { useAuth } from '../../context/AuthContext';


const WorkExperienceApproach = () => {
    const { setShowModal } = useAuth();
    const projects = [
        {
            type: "Independent Project",
            typeColor: { backgroundColor: '#03BE91' },
            title: "Build an OTT platform like Netflix Engineers",
            description: "Build a highly responsive, seamless video streaming service.",
            techStack: ["React JS", "Node JS", "Rest API", "Express JS"],
            imageUrl: netflixImage// replace with actual path or URL
        },
        {
            type: "Guided Project",
            typeColor: { backgroundColor: '#07634C' },
            title: "Work like Backend Developers at Zomato",
            description: "Build a high-scale distributed web backend for a food ordering app.",
            techStack: ["MongoDB", "Java", "Spring Boot", "Redis"],
            imageUrl: zomatoImage // replace with actual path or URL
        },
        {
            type: "Independent Project",
            typeColor: { backgroundColor: '#03BE91' },
            title: "Experience building a Google News like app",
            description: "Build a fully functional news aggregator like Google News.",
            techStack: ["HTML", "CSS", "Rest APIs", "Flexbox"],
            imageUrl: newsImage // replace with actual path or URL
        },
        {
            type: "Guided Project",
            typeColor: { backgroundColor: '#07634C' },
            title: "Work like Frontend Developers at AirBnB",
            description: "Build a highly responsive frontend for a travel app.",
            techStack: ["HTML", "CSS", "Bootstrap", "React JS"],
            imageUrl: airbnbImage // replace with actual path or URL
        },
        {
            type: "Guided Project",
            typeColor: { backgroundColor: '#07634C' },
            title: "Work like an Engineer at Zerodha",
            description: "Build a tool to analyse an  investment portfolio.",
            techStack: ["OOP", "JSON", "Gradle", "Rest API"],
            imageUrl: zerodhaImage // replace with actual path or URL
        },
        {
            type: "Guided Project",
            typeColor: { backgroundColor: '#07634C' },
            title: "Work like Full Stack Developers at Amazon",
            description: "Build a functional and scalable ecommerce app.",
            techStack: ["MongoDB", "React JS", "Node JS", "Express JS"],
            imageUrl: amazonImage // replace with actual path or URL
        }
    ];

    const [settings, setSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Default: Show 3 slides at a time
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        centerMode: false,
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 600) {
                setSettings((prevSettings) => ({
                    ...prevSettings,
                    slidesToShow: 1, // Show 1 slide on smaller screens
                }));
            } else {
                setSettings((prevSettings) => ({
                    ...prevSettings,
                    slidesToShow: 3, // Default: Show 3 slides on larger screens
                }));
            }
        };

        window.addEventListener('resize', handleResize);

        // Call the resize handler right away to set initial state
        handleResize();

        // Cleanup on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <div className="workExpApproach rubik-hero-section">
            <div>
                <div className='workTitle manrope-hero-section'><h2>Modern Work-Experience
                    Based Learning Approach </h2> </div>
                <p className='workPara'>Learn and grow like you would on a real job. Start from the fundamentals, get support from our mentors and community, and <strong className='text-black'> build your way to the top </strong> - through professional work-like Full-stack and Backend web development projects.</p>
            </div>
            <div className='slider-container'>
                <Slider {...settings} className='slider'>
                    {projects.map((project, index) => (
                        <div key={index} className="card rounded-[20px] bg-gradient-to-r from-blue-50 to-cyan-50">
                            <div className="card-header">
                                <span className="project-type" style={project.typeColor}>{project.type}</span>
                            </div>
                            <div className="card-content">
                                <img src={project.imageUrl} alt={project.title} className="project-image" />
                                <h3 className='text-lg font-bold px-8'>{project.title}</h3>
                                <p className='px-9 text-sm text-lightGrayLight'>{project.description}</p>
                                <div className="tech-stack mt-6">
                                    {project.techStack.map((tech, i) => (
                                        <span key={i} className="tech-item min-w-[100px]">{tech}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <button
            onClick={()=>{setShowModal(true)}}
                className="enrollIcon"
            >
                Talk to us
            </button>
        </div>
    );
}

export default WorkExperienceApproach;

import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance'

const Admin = () => {
    const [hireRequests, setHireRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchHireRequests = async () => {
            try {
                const response = await axiosInstance.get('hire'); 
                setHireRequests(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to load hire requests.');
                setLoading(false);
            }
        };

        fetchHireRequests();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p className="text-red-600">{error}</p>;

    return (
        <div className="container mx-auto px-4">
            <h1 className="text-3xl font-bold text-center my-8">Hire Requests</h1>
            <table className="min-w-full border-collapse border border-gray-300">
                <thead>
                    <tr>
                        <th className="border border-gray-300 px-4 py-2">Name</th>
                        <th className="border border-gray-300 px-4 py-2">Email</th>
                        <th className="border border-gray-300 px-4 py-2">Message</th>
                        <th className="border border-gray-300 px-4 py-2">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {hireRequests.map((request) => (
                        <tr key={request._id}>
                            <td className="border border-gray-300 px-4 py-2">{request.name}</td>
                            <td className="border border-gray-300 px-4 py-2">{request.email}</td>
                            <td className="border border-gray-300 px-4 py-2">{request.message}</td>
                            <td className="border border-gray-300 px-4 py-2">{new Date(request.createdAt).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Admin;

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Projects from './pages/Projects';
import Hire from './pages/HireFromUs';
import Admin from './pages/Admin';
import Home from './pages/Home';
import Login from './components/Login';
import Signup from './components/Signup';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Footer from './components/Footer';
import ChatAssistant from './components/ChatAssistant';
import CheckoutPage from './pages/CheckoutPage';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
 
const App = () => {
    return (
        
            <AuthProvider>
                <Router>
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/hire" element={<Hire />} />
                        <Route
                            path="/admin"
                            element={
                                <ProtectedRoute element={Admin} allowedRoles={['admin']} />
                            }
                        />
                        <Route 
                        path="/checkout" 
                        element={
                            <ProtectedRoute element={CheckoutPage} allowedRoles={['admin','student']} />
                        }
                         />
                        <Route path="*" element={<Navigate to="/" replace />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password/:token" element={<ResetPassword />} />
                        <Route path="/signup" element={<Signup />} />
                    </Routes>
                    <Footer />
                    <ChatAssistant />
                </Router>
            </AuthProvider>
    );
};

export default App;

import React from 'react'
import './style/FAQ.css'
import FAQItem from './FAQItem';

const FAQ = () => {
    const faqData = [
        { question: 'Is there any pre-requisite for this program?', answer: `No, there are no pre-requisites.` },
        { question: 'Who is eligible for the program?', answer: `Our program is open to anyone eager to upskill and advance their web development career. Whether you're a recent graduate, a working professional, or someone passionate about web development, this course is designed to help you build the skills you need to succeed.` },
        { question: 'What is the duration of this program?', answer: 'The program will take on average 6 months to complete. The program duration can increase or decrease by 1-2 months based on your current expertise level' },
        { question: 'Is this an online program?', answer: 'Yes. This is a completely online program.' },
        { question: 'How does the program work?', answer: 'This is a hands-on learning program designed to immerse you in building professional-grade projects within a real-world development environment. This program offer opportunities to connect directly with tech industry experts through personalized one-on-one sessions. With Learnify, you’ll gain in-demand tech skills and develop your career with confidence.' },
        { question: 'What if I miss any live sessions?', answer: 'All live sessions are recorded and you will have access to the recordings after the session is completed.' },
        { question: 'Are the timings flexible during the program?', answer: `You'll need to dedicate 15 hours per week to reach the program goals. You can flexibly distribute these hours throughout the week, aligning with your schedule, to stay on track with the program objectives.` },
        { question: 'Will I get a certificate at the end of the program?', answer: 'Yes, you will receive a certificate upon completion.' },
        { question: 'What if I decide to leave in the middle of the program?', answer: 'There will be no refund/no cancellation if you do not complete the program and leave midway.' },
    ];

    return (
        <div className='faq-container rubik-hero-section bg-white'>
            <div className='faq-header'>
                <h2 className='manrope-hero-section'>
                    FAQs
                </h2>
            </div>
            <div className='faqSection'>
                {faqData.map((faq, index) => (
                    <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </div>
    );
}


export default FAQ

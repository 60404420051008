// LogoCarousel.js
import React from "react";
import "./style/LogoCarousel.css";
import logoRow1 from "./asset/logosSection/logoList1.webp"; 
import logoRow2 from "./asset/logosSection/logoList2.webp"; 

const LogoCarousel = () => {
  return (
    <div className="company-logos mb-16">
      {/* Row 1 - Scrolling Left */}
      <div className="logos-row left-scroll">
        <img src={logoRow1} alt="Logos Row 1" />
        <img src={logoRow1} alt="Logos Row 1 Duplicate" />
      </div>

      {/* Row 2 - Scrolling Right */}
      <div className="logos-row right-scroll">
        <img src={logoRow2} alt="Logos Row 2" />
        <img src={logoRow2} alt="Logos Row 2 Duplicate" className="ml-8"/>
      </div>
    </div>
  );
};

export default LogoCarousel;

// src/components/Profile.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import '../styles/profile.css'

const Profile = ({ onClose }) => {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [refreshProfile, setRefreshProfile] = useState(false); // trigger to refetch data

    useEffect(() => {
        const checkStatus = async () => {
            try {
                const response = await axiosInstance.get('/auth/checkAuth');
                if (response.data.success) {
                    const { username, email, mobileNo } = response.data.user;
                    setUserName(username);
                    setEmail(email);
                    setPhone(mobileNo)
                }
            } catch (error) {
                console.error('Error checking authentication status:', error);
            }
        };


        checkStatus();
    }, [refreshProfile]);

    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        try {
            await axiosInstance.put('/user/profile', { userName, email, phone });
            setMessage('Profile updated successfully');

            setRefreshProfile(!refreshProfile);
        } catch (err) {
            console.error(err);
            setError('Failed to update profile');
        }
    };


    const handleChangePassword = async (e) => {
        e.preventDefault();
        try {
            await axiosInstance.post('/user/change-password', { oldPassword, newPassword })
            setMessage('Password changed successfully');
            setOldPassword('');
            setNewPassword('');
        } catch (err) {
            console.error(err);
            setError('Failed to change password');
        };
    };

    return (
        <div className="profile-overlay">
            <div className="profile-container">
                <button className="close-button" onClick={onClose}>×</button>
                <h2>User Profile</h2>

                {error && <div className="error">{error}</div>}
                {message && <div className="message">{message}</div>}

                <form onSubmit={handleProfileUpdate}>
                    <div>
                        <label>Name:</label>
                        <input
                            type="text"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Phone:</label>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Update Profile</button>
                </form>

                <h3>Change Password</h3>
                <form onSubmit={handleChangePassword}>
                    <div>
                        <label>Old Password:</label>
                        <input
                            type="password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>New Password:</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Change Password</button>
                </form>
            </div>
        </div>
    );
};

export default Profile;

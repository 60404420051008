// Card.js
import React from 'react';
import './style/Cards.css';

const Card = ({ icon, title, description, highlight }) => {
  return (
    <div className="card-master-approach bg-gradient-to-r from-blue-100 to-cyan-100">
      <img src={icon} alt="Card Icon" className="card-icon-master-approach" />
      <div className='master-card-content'>
        <h3 className='font-bold text-sm text-black'>{title}</h3>
        <p>{description} <span>{highlight}</span></p>
      </div>
    </div>
  );
};

export default Card;

// Footer.js
import React from 'react';
import '../styles/footer.css'; 
import logo from './homeComponents/asset/logo-update.png';

const Footer = () => {
  return (
    <footer className="footer rubik-hero-section bg-gradient-to-r from-black to-customLightBlack">
      <div className="footer-content">
        
        {/* Logo and Tagline */}
        <div className="footer-logo">
          <img src={logo} alt="Learnify Logo" className="logo" />
        </div>

        {/* Contact Information */}
        <div className="footer-contact">
          <h4 className='manrope-hero-section'>Contact Us</h4>
          <p>Email: support@learnifyhub.in</p>
          <p>Phone: +91-9705142006</p>
        </div>

        {/* Social Media Links */}
        <div className="footer-social">
          {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <i className="fab fa-facebook-f"></i>
          </a> */}
          <a href="https://www.linkedin.com/company/learnify-tech" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a href="https://x.com/Learnify_Tech" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/learnify.hub/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>

      {/* Copyright */}
      <div className="footer-bottom">
        <p>© 2024 Learnify. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
